<template>
  <b-modal id="modal-fee-category-detail" ref="refModalFeeCategoryDetail" :title="t('Thông tin loại dịch vụ')" size="lg"
    scrollable :hide-footer="true" no-close-on-backdrop @show="onOpen" @hidden="resetModal">

    <div class="d-flex justify-content-center mb-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div v-if="itemLocal.quota"
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        {{ t('Thông tin loại dịch vụ') }}
      </div>
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên loại dịch vụ') }}:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>


    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useFeeCategoryDetailModal from './useFeeCategoryDetailModal';
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    VueGoodTable
  },
  props: {
    feeCategoryId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      t,
    } = useFeeCategoryDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }

    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
