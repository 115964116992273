import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import feeCategoryStoreModule from '../feeCategoryStoreModule';

export default function useFeeCategoryList() {
  const STORE_MODULE_NAME = 'feeCategory';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, feeCategoryStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'fee-category') && !vm.$can('delete', 'fee-category');
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Tên loại dịch vụ'),
      field: 'name',
    },
  ];
  const rows = ref([]);
  // filter

  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchFeesCategory = () => {
    store
      .dispatch('feeCategory/fetchFeesCategory', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteFeesCategory = feesCategory => {
    store
      .dispatch('feeCategory/deleteFeesCategory', {
        ids: feesCategory.map(_obj => _obj.id),
      })
      .then(() => {
        fetchFeesCategory();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchFeesCategory();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const enableDelete = vals => {
    if (vals && vals.length > 0) {
      // kiem tra xem co item nao la mac dinh hay khong
      const numOfDefault = vals.filter(_obj => _obj.default === true).length;
      if (numOfDefault > 0) {
        return false;
      }
      return true;
    }
    return false;
  };

  return {
    item,
    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    enableDelete,
    fetchFeesCategory,
    deleteFeesCategory,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    t,
  };
}
