import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFeesCategory(ctx, params) {
      return useJwt.getFeesCategory(params).then(response => response);
    },
    createFeeCategory(ctx, data) {
      return useJwt.createFeeCategory(data).then(response => response);
    },
    updateFeeCatergory(ctx, data) {
      return useJwt.updateFeeCategory(data).then(response => response);
    },
    getFeeCategoryDetail(ctx, id) {
      return useJwt.getFeeCategoryDetail(id).then(response => response);
    },
    deleteFeesCategory(ctx, data) {
      return useJwt.deleteFeesCategory(data).then(response => response);
    },
  },
};
