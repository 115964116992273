<template>
  <b-modal id="modal-fee-category" ref="refModal" :title="t('Dịch vụ')" size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')" :ok-disabled="isSubmitting" :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary" :hide-header-close="true" no-close-on-backdrop @show="resetModal"
    @hidden="resetModal" @ok="onSubmit">
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
        <b-row>
          <b-col>
            <validation-provider #default="validationContext" name="name" rules="required">
              <b-form-group :label="t('Tên dịch vụ')">
                <template v-slot:label>
                  {{ t("Tên loại dịch vụ") }}
                  <span class="text-danger"> (*) </span>
                </template>
                <b-input-group>
                  <b-form-input id="name" v-model="itemLocal.name" placeholder="Tiền ăn" trim
                    :state="getValidationState(validationContext)" />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import useFeeCategoryModal from "./useFeeCategoryModal";
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit
    } = useFeeCategoryModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t
    };
  },
};
</script>
